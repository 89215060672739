<template>
    <section style="background-color: rgb(18, 18, 30); min-height: 100vh; width: 100%">
        <div class="w-100 p-2 d-flex justify-content-around" style="
        background-color: var(--red) !important;
        position: sticky;
        top: 0;
        z-index: 40;
      ">
            <!-- <a class="btn btn-link btn-sm" style="color: #fff;font-size:medium;margin-top: 3px;" @click="goBack()">
                <svg style="margin-bottom: -3px;" fill="#fff" class="material-design-icon__svg" width="15" height="15"
                    viewBox="0 0 24 24">
                    <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z">
                    </path>
                </svg>
                <span> Back</span>
            </a> -->

            <div class="w-70 mt-2" style="min-width: 50%">
                <!-- <p style="color: var(--yellow);font-size:medium;">Earn 500K KES per month</p> -->
            </div>

            <div class="w-30">
                <router-link to="/">
                    <i class="bi bi-x" style="
              color: red;
              cursor: pointer;
              font-size: 20px;
              font-weight: bold;
            "></i>
                </router-link>
            </div>
        </div>
        <section v-if="activeTab === 'panel'">
            <div class="mt-3 d-flex flex-column today-container">
                <p class="p-1 fw-bolder  mb-4 m-1" style="color: #ffffff; border-bottom: solid var(--yellow);">
                    Share By email or on social media
                </p>

                <div class="d-flex justify-content-around" style="width: 100%">
                    <div class="d-flex flex-column" style="width: 16%">
                        <div @click.prevent="shareOnSocial('facebook')" class="btn btn-fab btn-round btn-facebook">
                            <img style="width: 30px;" loading="lazy" class="social-btn" src="/img/facebook.png" />
                        </div>
                        <span class="text-center words-span">FB</span>
                    </div>

                    <div class="d-flex flex-column" style="width: 16%">
                        <div @click.prevent="shareOnSocial('whatsapp')" class="btn btn-fab btn-round btn-facebook">
                            <img style="width: 30px;" loading="lazy" class="social-btn"
                                src="/img/home/whatsapp-color.png" />
                        </div>
                        <span class="text-center words-span">WhatsApp</span>
                    </div>

                    <div class="d-flex flex-column" style="width: 16%">
                        <div @click.prevent="shareOnSocial('telegram')" class="btn btn-fab btn-round btn-facebook">
                            <img style="width: 30px;" loading="lazy" class="social-btn"
                                src="/img/home/telegram-color.png" />
                        </div>
                        <span class="text-center words-span">Telegram</span>
                    </div>


                    <div class="d-flex flex-column" style="width: 16%">
                        <div @click.prevent="shareOnSocial('twitter')" target="_blank"
                            class="btn btn-fab btn-round btn-facebook">
                            <img style="width: 30px;" loading="lazy" class="social-btn"
                                src="/img/home/twitter-color.png" />
                        </div>
                        <span class="text-center words-span">X</span>
                    </div>


                    <div class="d-flex flex-column" style="width: 16%">
                        <div @click.prevent="shareOnSocial('instagram')" target="_blank"
                            class="btn btn-fab btn-round btn-facebook">
                            <img style="width: 30px;" loading="lazy" class="social-btn" src="/img/home/instagram.png" />
                        </div>
                        <span class="text-center words-span">IG</span>
                    </div>

                    <div class="d-flex flex-column" style="width: 16%">
                        <a v-bind:href="getSMSContent" target="_blank" class="btn btn-fab btn-round btn-facebook">
                            <img style="width: 30px;" loading="lazy" class="social-btn" src="/img/home/sms.png" />
                        </a>
                        <span class="text-center words-span">SMS</span>
                    </div>

                </div>

                <p style="margin-top: 14px; font-size: 14px; text-align: center;color: #ffffff;">
                    Share referral link with friends and followers
                </p>

                <div class="copy-div">
                    <p class=" ml-4" style="color: var(--yellow); font-weight: bold;padding-left: 16px;"
                        id="copy-url">
                        https://falconbet.co.ke/?referal={{ myPerformanceSummary.code }}
                    </p>
                    <p class="mr-4">
                        <a @click="copyToClipboard" class="affiliate-button py-2 form-control"
                            style="color: var(--yellow);">
                            Copy
                        </a>
                    </p>
                </div>
            </div>
            <div class="mt-3 d-flex flex-column today-container">
                <div class="d-flex g-2 m-1" style="padding: 2px; ">
                    <p style="color: white;  font-weight: bolder">Today</p>
                    <div
                        style="background-color: #f9a21a; margin-left: 8px; width: 18px; height: 18px; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
                        <img style="width: 12px;" loading="lazy" src="/img/home/question.png" />
                    </div>
                </div>

                <div class="d-flex justify-content-between today mx-2">
                    <div class="row d-flex justify-content-around today-left ">
                        <div class="col mb-4 ml-2 mt-2" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.daily.signup }}</div>
                            <div class="today-left-text">Signups</div>
                        </div>

                        <div class="col mb-4 mt-2" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.daily.players }}</div>
                            <div class="today-left-text">Players</div>
                        </div>

                        <div class="col mb-4 mt-2" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.daily.deposit }}</div>
                            <div class="today-left-text">Deposits</div>
                        </div>

                        <div class="col mb-4 mt-2" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.daily.commission }} </div>
                            <div class="today-left-text">Commission</div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="mt-3 d-flex flex-column today-container ">
                <div class="d-flex g-2 m-1" style="padding: 2px;">
                    <p style="color: white; font-weight: bolder">Total</p>
                    <div
                        style="background-color: #f9a21a; margin-left: 8px; width: 18px; height: 18px; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
                        <img style="width: 12px;" loading="lazy" src="/img/home/question.png" />
                    </div>
                </div>

                <div class="d-flex justify-content-between today mx-2">
                    <div class="row d-flex justify-content-around today-left">
                        <div class="col mb-4 ml-2" style="border-top: solid var(--yellow) 1px; ">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.overall.signup }}</div>
                            <div class="today-left-text">Signups</div>
                        </div>

                        <div class="col mb-4" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.overall.players }}</div>
                            <div class="today-left-text">Players</div>
                        </div>

                        <div class="col mb-4" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.overall.deposit }}</div>
                            <div class="today-left-text">Deposits</div>
                        </div>

                        <div class="col mb-4" style="border-top: solid var(--yellow) 1px;">
                            <div class="today-left-counter mt-2">{{ myPerformanceSummary.overall.commission }} </div>
                            <div class="today-left-text">Commission</div>
                        </div>

                    </div>
                </div>
            </div>


            <div class="mt-3 d-flex flex-column today-container" style="">
                <div class="d-flex g-2 m-1" style="padding: 2px; border-bottom: solid var(--yellow);">
                    <p style="color: white; font-weight: bold">Commission</p>
                    <div
                        style="background-color: #f9a21a; margin-left: 8px; width: 18px; height: 18px; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
                        <img style="width: 12px;" loading="lazy" src="/img/home/question.png" />
                    </div>
                </div>

                <div class="row d-flex justify-content-between p-1 today" >
                    <div class="col d-flex justify-content-center mb-2">
                        <div class="commission-left">
                            <img style="width: 40px;" loading="lazy" class="social-btn" src="/img/home/crowns.png" />
                            <p class="icon-p">
                                Agent : <span class="icon-c">{{ myPerformanceSummary.code }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="col d-flex flex-column justify-content-around commission-right">
                        <div class="d-flex justify-content-center">
                            <div class="commission-right-paid-unpaid">
                                <div class="today-left-counter">{{ myPerformanceSummary.wallet_summary.balance }}</div>
                                <div class="today-left-text">Paid</div>
                            </div>

                            <div class="commission-right-paid-unpaid">
                                <div class="today-left-counter">{{ myPerformanceSummary.wallet_summary.total_commissions
                                    }}</div>
                                <div class="today-left-text">
                                    commissions
                                </div>
                            </div>
                        </div>

                        <div>
                            <TransferWallet />

                            <p class="transfer-text">
                                Available for over KES 100
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            
        </section>
    </section>
</template>

<script>
import affiliate from "@/services/affiliate";
import TransferWallet from "./settings/TransferWallet.vue";

export default {
    name: "Affiliates",
    components: {
        TransferWallet,
    },
    data() {
        return {
            activeTab: "panel",
            showCalendar: false,
            selectedDate: null,
            myProfile: null,
            myPerformanceSummary: 0,
        };
    },
    mounted() {

        this.fetchAffiliatePerformance();
        console.log(this.fetchAffiliatePerformance());
        window.$(this.$refs.redeemModal).on('hidden.bs.modal', this.reset);
        this.myProfile = this.getProfile();
        if (this.myProfile) {
            this.code = this.myProfile.referral_code;
        }
        if (!this.myProfile) {
            this.setError("Login", "Please login to proceed");
            this.$router.push({ name: "login", params: {} });
            return;
        }
    },
    methods: {
        copyToClipboard() {
            const textToCopy = document.getElementById('copy-url').textContent;

            const tempTextarea = document.createElement('textarea');
            tempTextarea.value = textToCopy;
            document.body.appendChild(tempTextarea);

            tempTextarea.select();
            document.execCommand('copy');

            document.body.removeChild(tempTextarea);

            this.$toast.open({
                message: 'Copied to clipboard',
                type: 'success',
                position: 'top'
            });
        },

        fetchAffiliatePerformance() {
            var vm = this;

            const path = process.env.VUE_APP_AFFILIATE_BASE_URL + "/performance/summary";
            console.log(this.getAuth())

            affiliate.get(path, {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': vm.getAuth()
                }
            })
                .then(response => {
                    this.myPerformanceSummary = response.data;
                    console.log('Performance Summary :', response.data);
                })
                .catch(err => {
                    console.error('Error Performance Summary :', err);
                });
        },
        reset() {
            this.code = null;
            this.message = null;
            this.loading = false;
        },

        affiliate() {
            this.message = null;
            var p = this.getAuth();
            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var vm = this;
            var path = "/join";

            var data = {
                code: this.code,
            };

            this.loading = true;

            affiliate
                .post(path, JSON.stringify(data), {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((res) => {
                    var msg = res.data.data;
                    this.message = {
                        message: msg,
                        type: 'success'
                    }

                })
                .catch((err) => {
                    if (err.response) {
                        if (
                            parseInt(err.response.data.error_code) === 401 ||
                            parseInt(err.response.data.error_code) === 400 ||
                            parseInt(err.response.data.error_code) === 428
                        ) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            // vm.logout();
                            return;
                        } else {
                            this.message = {
                                message: err.response.data.error_message ?? 'Failed',
                                type: 'error'
                            }

                        }
                    } else if (err.request) {
                        this.message = {
                            message: 'Network Error. Check your network connection and try again',
                            type: 'error'
                        }


                    } else {
                        this.message = {
                            message: 'An error occurred. Please try again',
                            type: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading = false

                })
        },
        shareOnSocial(platform) {

            const shareUrl = `https://falconbet.co.ke/?referal=${this.myPerformanceSummary.code}`
            const shareText = `Win big on falconbet.co.ke, mimi hubet na Falconbet.co.ke, trustable and legit, click on this link to join me and get 2000Ksh bonus\n\n${shareUrl}`;

            let url;
            switch (platform) {
                case 'facebook':
                    url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
                    break;
                case 'instagram':
                    url = `https://instagram.com/tucheze?utm_medium=copy_link&t=${encodeURIComponent(shareUrl)}`;
                    break;
                case 'whatsapp':
                    url = `https://wa.me/?text=${encodeURIComponent(shareText)}`;
                    break;
                case 'twitter':
                    url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
                    break;
                case 'telegram':
                    url = `https://telegram.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
                    break;
                default:
                    return;
            }
            window.open(url, '_blank');

        },
    }
};
</script>

<style scoped>
.tabs {
    font-size: 12px;
    width: 15;
    color: #9c8ead;
}

.activeTab {
    border-bottom: 2px solid green;
    font-weight: bold;
    color: #009d80;
}

.today-container {
    background-color: #2c2d41;
    border-radius: 10px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.today {
    width: 100%;
    display: flex;
    justify-content: around;
}

.today-left {
    width: 100%;
}

.today-left-counter {
    display: flex;
    justify-content: center;
    color: white;
    font-weight: bolder;
    font-size: 18px;
}

.today-left-text {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    color: white;
}

.commission-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2c2d41;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.commission-right-paid-unpaid {
    width: 100%;
}

.transfer-wallet {
    width: 100%;
    color: #8a8a8a;
    background-color: #ffffff !important;
    border: 1px solid;
    border-radius: 3px;
}

.transfer-text {
    text-align: center;
    font-size: 12px;
    color: white;
    margin-top: 10px;
}

.icon {
    font-size: 46px;
    text-align: center;
    color: #009d80;
}

.icon-p {
    text-align: center;
    color: var(--yellow);
    font-weight: 600;
}

.icon-c {
    text-align: center;
    color: var(--yellow);
    font-weight: 600;
}

.words-span {
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
    color: #ffffff;
}

.copy-div {
    background-color: #2c2d41;
    width: 100%;
    border-radius: 3px;
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Center content vertically */
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
}


/* Style for the sliding calendar */
.calendar-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 100;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* Slide-up transition */
.slide-up-enter-active,
.slide-up-leave-active {
    transition: transform 0.4s ease;
}

.slide-up-enter,
.slide-up-leave-to

/* 'leave-to' for Vue 2.1.8+ */
    {
    transform: translateY(100%);
}

/* Footer styling for calendar */
.calendar-footer {
    text-align: center;
    padding: 10px;
}

.confirm-btn {
    background-color: #7a00cb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.date-input-container {
    display: flex;
    align-items: center;
    margin: 20px;
    padding: 10px;
}

.date-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.bi-calendar {
    font-size: 1.5em;
    cursor: pointer;
}

@media screen and(max-width:310px) {
    .today {
        flex-direction: column;
    }
}

.affiliate-button {
    background-color: var(--darkBackground);
    color: var(--yellow);

}
</style>